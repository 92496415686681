// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig";

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
    const item = window.localStorage.getItem("menuCollapsed");
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

// ** Initial State
const initialState = {
    isRTL: themeConfig.layout.isRTL,
    menuCollapsed: initialMenuCollapsed(),
    menuHidden: themeConfig.layout.menu.isHidden,
    contentWidth: themeConfig.layout.contentWidth,
    customizer: themeConfig.layout.customizer,
    navbarHidden: themeConfig.layout.navbar.type,
    img: '',
    pennyStocks: [],
    plans: "",
    ChartData: [],
    WhyChartData: [],
    trialDays: 0,
    isTrialAccount: false
};
const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ALL_PENNY_STOCKS": {
            return { ...state, pennyStocks: action.value };
        }
        case "SET_USER_PLANS": {
            return { ...state, plans: action.value };
        }
        case "SET_DASHBOARD_CHART_DATA": {
            return { ...state, ChartData: action.value };
        }
        case "SET_WHY_CHART_DATA": {
            return { ...state, WhyChartData: action.value };
        }
        case "SET_PROFILE_ICON": {
            return { ...state, img: action.value };
        }
        case "SET_CUSTOMIZER": {
            return { ...state, customizer: action.value };
        }
        case "SET_NAVBAR_HIDDEN": {
            return { ...state, navbarHidden: action.value };
        }
        case "SET_MENUHIDDEN_BYUSER": {
            return { ...state, menuHidden: action.value };
        }
        case "SET_TRIALS_DAYS": {
            return { ...state, trialDays: action.value[0], isTrialAccount: action.value[1] };
        }
        case "HANDLE_CONTENT_WIDTH":
            return { ...state, contentWidth: action.value };
        case "HANDLE_MENU_COLLAPSED":
            window.localStorage.setItem("menuCollapsed", action.value);
            return { ...state, menuCollapsed: action.value };
        case "HANDLE_MENU_HIDDEN":
            return { ...state, menuHidden: action.value };
        case "HANDLE_RTL":
            return { ...state, isRTL: action.value };
        default:
            return state;
    }
};

export default layoutReducer;
