// ** React Imports
import { useState, createContext, useEffect, useContext } from "react";
import { useQuery } from "react-apollo";
import { toast } from "react-toastify";
import { ConfirmationModal } from "ui/components/Alert";
import { GET_FORMULA_BY_CALCULATION } from "ui/pages/ConfigurationCalculation/Formula/FormulaQuery";
import { IntlContext } from "./Internationalization";

// ** Create Context
const RefreshBtnContext = createContext();

const RefreshBtnProviderWrapper = ({ children }) => {
	const intlContext = useContext(IntlContext);
	// const { c_id } = useParams();

	// ** States
	const [isUpdatable, setIsUpdatable] = useState(false);
	const [refreshFormula, setRefreshFormula] = useState(false);
	const [calculationId, setCalculationId] = useState();
	const [chapterId, setChapterId] = useState();
	const [sectionId, setSectionId] = useState();
	const [subSectionId, setSubSectionId] = useState();
	const [needToRefreshFormulas, setNeedToRefreshFormulas] = useState([]);
	const toggleIsUpdatable = (val) => setIsUpdatable(val);
	const toggleRefreshFormula = (val) => setRefreshFormula(val);
	const toggleCalculationId = (val) => setCalculationId(val);
	const toggleChapterId = (val) => setChapterId(val);
	const toggleSectionId = (val) => setSectionId(val);
	const toggleSubSectionId = (val) => setSubSectionId(val);

	const {
		data: formula,
		loading,
		refetch: refetchFormula,
	} = useQuery(GET_FORMULA_BY_CALCULATION, {
		variables: {
			getFormulasByCalculationId: calculationId,
			chapterId: chapterId,
			sectionId: sectionId,
			subSectionId: subSectionId,
			isSection: false,
			isGetAll: window?.location?.pathname?.includes("pdfview") ? true : false,
		},
		skip: (!calculationId && !chapterId && !sectionId && !subSectionId) ? true : false,
		fetchPolicy: "cache-and-network",
	});
	useEffect(() => {
		(async function () {
			if (formula?.getFormulasByCalculation) {
				const tempArray = [];
				await Promise.all(
					formula?.getFormulasByCalculation?.map(async (formula) => {
						tempArray?.push(formula?.isUpdatable);
						if (formula?.isUpdatable) {
							setIsUpdatable(true);
						}
						return true;
					})
				);
				if (tempArray?.length && !tempArray?.some((val) => val) && !loading || refreshFormula) {
					if (refreshFormula) {
						ConfirmationModal(
							"success",
							intlContext?.messages?.Updated,
							intlContext?.messages?.AllFormulasAreUpdated,
							""
						);
					}
					setRefreshFormula(false);
				}
			}
		}())
	}, [formula]);

	const toggleAllReset = () => {
		setIsUpdatable(false);
		setRefreshFormula(false);
	};

	return (
		<RefreshBtnContext.Provider
			value={{
				isUpdatable,
				toggleIsUpdatable,
				refreshFormula,
				calculationId,
				toggleCalculationId,
				chapterId,
				toggleChapterId,
				sectionId,
				toggleSectionId,
				subSectionId,
				toggleSubSectionId,
				formula: formula?.getFormulasByCalculation,
				formulaLoading: loading,
				refetchFormula,
				toggleRefreshFormula,
				toggleAllReset,
				needToRefreshFormulas,
				setNeedToRefreshFormulas,
			}}
		>
			{children}
		</RefreshBtnContext.Provider>
	);
};

export { RefreshBtnProviderWrapper, RefreshBtnContext };
