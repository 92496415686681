// ** React Imports
import { useState, createContext, useEffect } from "react";
import { useQuery } from "react-apollo";
import { GET_ALL_UNITY_CHECKS_BY_CALCULATION } from "ui/pages/ConfigurationCalculation/UnityChecks/UnityChecksQuery";

// ** Create Context
const RefreshUnityChecksBtnContext = createContext();

const RefreshUnityChecksBtnProviderWrapper = ({ children }) => {
	// const { c_id } = useParams();

	// ** States
	const [isUpdatable, setIsUpdatable] = useState(false);
	const [refreshUnityChecks, setRefreshUnityChecks] = useState(false);
	const [calculationId, setCalculationId] = useState();
	const [chapterId, setChapterId] = useState();
	const [sectionId, setSectionId] = useState();
	const [subSectionId, setSubSectionId] = useState();
	const toggleIsUpdatable = (val) => setIsUpdatable(val);
	const toggleRefreshUnityChecks = (val) => setRefreshUnityChecks(val);
	const toggleCalculationId = (val) => setCalculationId(val);
	const toggleChapterId = (val) => setChapterId(val);
	const toggleSectionId = (val) => setSectionId(val);
	const toggleSubSectionId = (val) => setSubSectionId(val);

	const { data, refetch: refetchUnityChecks } = useQuery(GET_ALL_UNITY_CHECKS_BY_CALCULATION, {
		variables: {
			getUnityChecksByCalculationId: calculationId,
			chapterId: chapterId,
			sectionId: sectionId,
			subSectionId: subSectionId,
			isSection: false,
			isGetAll: window?.location?.pathname?.includes("pdfview") ? true : false,
		},
		skip: (!calculationId && !chapterId && !sectionId && !subSectionId) ? true : false,
		fetchPolicy: "cache-and-network",
	});
	useEffect(() => {
		if (data?.getUnityChecksByCalculation) {
			data?.getUnityChecksByCalculation?.map((data) => {
				if (data?.isUpdatable) {
					setIsUpdatable(true);
				}
			});
		}
	}, [data]);

	const toggleAllReset = () => {
		setIsUpdatable(false);
		setRefreshUnityChecks(false);
	};

	return (
		<RefreshUnityChecksBtnContext.Provider
			value={{
				isUpdatable,
				toggleIsUpdatable,
				refreshUnityChecks,
				calculationId,
				toggleCalculationId,
				chapterId,
				toggleChapterId,
				sectionId,
				toggleSectionId,
				subSectionId,
				toggleSubSectionId,
				data: data?.getUnityChecksByCalculation,
				refetchUnityChecks,
				toggleRefreshUnityChecks,
				toggleAllReset,
			}}>
			{children}
		</RefreshUnityChecksBtnContext.Provider>
	);
};

export { RefreshUnityChecksBtnProviderWrapper, RefreshUnityChecksBtnContext };
