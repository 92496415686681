import { gql } from "graphql-tag";

export const GET_ALL_FORMULA = gql`
	query GetAllFormula($page: Int, $limit: Int, $sort: Sort, $search: String, $isGlobal: Boolean, $isChapter: Boolean) {
		getAllFormula(page: $page, limit: $limit, sort: $sort, search: $search, isGlobal: $isGlobal, isChapter: $isChapter) {
			count
			data {
				id
				formulaName
				nameAsciiMath
				nameCleanedAsciiMath
				nameNeededToClean
				formulaUnit
				formula
				asciiMath
				cleanedAsciiMath
				output
				description
				isUpdatable
				usedVariables
				importantFormula
				calculationId
				isGlobal
				ignoreUnits
				scientificNotation
				decimals
				isActive
				createdBy
				updatedBy
				createdAt
				updatedAt
				subSectionId {
					id
					title
					subTitle
					description
					status
					sectionId
					calculationId
					chapterId
				}
				sectionId {
					id
					title
					subTitle
					description
					status
					companyId
					isTemplate
					calculationId
					chapterId
				}
			}
		}
	}
`;

export const GET_FORMULA_BY_CALCULATION = gql`
	query GetFormulasByCalculation($getFormulasByCalculationId: ID, $chapterId: ID, $sectionId: ID, $subSectionId: ID, $isSection: Boolean, $isGetAll: Boolean) {
		getFormulasByCalculation(id: $getFormulasByCalculationId, chapterId: $chapterId, sectionId: $sectionId, subSectionId: $subSectionId, isSection: $isSection, isGetAll: $isGetAll) {
			id
			formulaName
			nameAsciiMath
			nameCleanedAsciiMath
			nameNeededToClean
			formulaUnit
			formula
			asciiMath
			cleanedAsciiMath
			evaluated
			output
			description
			isUpdatable
			importantFormula
			reference
			usedVariables
			subSectionId
			sectionId
			chapterId
			calculationId
			isGlobal
			ignoreUnits
			scientificNotation
			decimals
			isActive
			createdBy
			updatedBy
			createdAt
			updatedAt
			variables {
				variableId
				value
				selectedValue
				selectedUnit
				sectionId
				subSectionId
				latex
			}
		}
	}
`;

export const GET_FORMULA = gql`
	query GetFormula($getFormulaId: ID) {
		getFormula(id: $getFormulaId) {
			id
			formulaName
			nameAsciiMath
			nameCleanedAsciiMath
			nameNeededToClean
			formulaUnit
			formula
			asciiMath
			cleanedAsciiMath
			output
			description
			isUpdatable
			reference
			importantFormula
			usedVariables
			subSectionId
			sectionId
			calculationId
			chapterId
			isGlobal
			ignoreUnits
			scientificNotation
			decimals
			isActive
			createdBy
			updatedBy
			createdAt
			updatedAt
		}
	}
`;
