// ** React Imports
import { useState, createContext, useEffect, useMemo } from 'react';
import numeral from 'numeral';

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
// import messagesDe from '@assets/data/locales/de.json'
import messagesFr from '@assets/data/locales/fr.json'
// import messagesPt from '@assets/data/locales/pt.json'
import messagesNl from '@assets/data/locales/nl.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
// import userMessagesDe from '@src/assets/data/locales/de.json'
import userMessagesFr from '@src/assets/data/locales/fr.json'
// import userMessagesPt from '@src/assets/data/locales/pt.json'
import userMessagesNl from '@src/assets/data/locales/nl.json'

// ** Menu msg obj
const menuMessages = {
    // en: { ...messagesEn, ...userMessagesEn },
    // nl: { ...messagesNl, ...userMessagesNl },
    // // de: { ...messagesDe, ...userMessagesDe },
    // fr: { ...messagesFr, ...userMessagesFr },
    // pt: { ...messagesPt, ...userMessagesPt }
    en: { ...userMessagesEn },
    nl: { ...userMessagesNl },
    // de: { ...messages},
    fr: { ...userMessagesFr },
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
    // ** States
    const defaultLocale = useMemo(() => localStorage?.getItem("locale"))
    const [locale, setLocale] = useState()
    const [messages, setMessages] = useState(menuMessages[locale])

    // ** Switches Language
    const switchLanguage = lang => {
        setLocale(lang)
        numeral.locale(lang);
        localStorage?.setItem("locale", lang)
    }

    useEffect(() => {
        locale && setMessages(menuMessages[locale])
    }, [locale])

    useEffect(() => {
        localStorage?.setItem("locale", defaultLocale ? defaultLocale : "en")
        setLocale(defaultLocale)
        if (numeral.locales.en == undefined) {
            numeral?.register('locale', 'en', {});
        } else if (numeral.locales.nl == undefined) {
            numeral?.register('locale', 'nl', {});
        }
    }, [defaultLocale])

    return (
        <Context.Provider value={{ locale, switchLanguage, messages }}>
            <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
                {children}
            </IntlProvider>
        </Context.Provider>
    )
}

export { IntlProviderWrapper, Context as IntlContext }
