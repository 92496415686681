import { gql } from "graphql-tag";

export const GET_ALL_UNITY_CHECKS = gql`
	query GetAllUnityCheck($page: Int, $limit: Int, $sort: Sort, $search: String, $isGlobal: Boolean, $isChapter: Boolean) {
		getAllUnityCheck(page: $page, limit: $limit, sort: $sort, search: $search, isGlobal: $isGlobal, isChapter: $isChapter) {
			count
			data {
				id
				unityCheckUnit
				formula
				asciiMath
				cleanedAsciiMath
				output
				description
				type
				status
				passMsg
				failMsg
				isUpdatable
				reference
				subSectionId
				sectionId
				calculationId
				chapterId
				isActive
				createdBy
				updatedBy
				createdAt
				updatedAt
				variables {
					variableId
					value
					selectedValue
					selectedUnit
				}
			}
		}
	}
`;
export const GET_ALL_UNITY_CHECKS_BY_CALCULATION = gql`
	query GetUnityChecksByCalculation($getUnityChecksByCalculationId: ID, $chapterId: ID, $sectionId: ID, $subSectionId: ID, $isSection: Boolean, $isGetAll: Boolean) {
		getUnityChecksByCalculation(id: $getUnityChecksByCalculationId, chapterId: $chapterId, sectionId: $sectionId, subSectionId: $subSectionId, isSection: $isSection, isGetAll: $isGetAll) {
			id
			unityCheckUnit
			formula
			asciiMath
			cleanedAsciiMath
			output
			description
			type
			status
			passMsg
			failMsg
			isUpdatable
			reference
			subSectionId
			sectionId
			calculationId
			chapterId
			isActive
			createdBy
			updatedBy
			createdAt
			updatedAt
			evaluated
			variables {
				variableId
				value
				selectedValue
				selectedUnit
				sectionId
				subSectionId
				latex
			}
		}
	}
`;
export const GET_UNITY_CHECKS = gql`
	query GetUnityCheck($getUnityCheckId: ID) {
		getUnityCheck(id: $getUnityCheckId) {
			id
			unityCheckUnit
			formula
			asciiMath
			cleanedAsciiMath
			output
			description
			type
			status
			passMsg
			failMsg
			isUpdatable
			reference
			subSectionId
			sectionId
			calculationId
			chapterId
			isActive
			createdBy
			updatedBy
			createdAt
			updatedAt
			variables {
				variableId
				value
				selectedValue
				selectedUnit
			}
		}
	}
`;
